.c-remove.clear-all {
  display: none;
}
.btn-primary {
  .c-btn {
    color: $primary !important;
    border: 1px solid $primary !important;
    background: transparent !important;
    .c-angle-down {
      height: 7px;
      width: 7px;
      top: 14px;
      svg {
        fill: $primary;
      }
    }
    .c-angle-up {
      height: 7px;
      width: 7px;
      top: 14px;
      svg {
        fill: $primary;
      }
    }
    .c-list .c-token {
      background: none !important;
      .c-remove svg {
        fill: $primary !important;
      }
    }
  }
  .dropdown-list {
    .list-area {
      li {
        &.selected-item {
          background-color: $primary !important;
          label {
            color: #fff !important;
            text-decoration: none !important;
          }
        }
      }
    }
  }
}
.btn-info {
  .c-btn {
    color: $info !important;
    border: 1px solid $info !important;
    background: transparent !important;
    .c-angle-down {
      height: 7px;
      width: 7px;
      top: 14px;
      svg {
        fill: $info;
      }
    }
    .c-angle-up {
      height: 7px;
      width: 7px;
      top: 14px;
      svg {
        fill: $info;
      }
    }
    .c-list .c-token {
      background: none !important;
      .c-remove svg {
        fill: $info !important;
      }
    }
  }

  .dropdown-list {
    .list-area {
      li {
        &.selected-item {
          background-color: $info !important;
          label {
            color: #fff !important;
            text-decoration: none !important;
          }
        }
      }
    }
  }
}
.btn-danger {
  .c-btn {
    color: $danger !important;
    border: 1px solid $danger !important;
    background: transparent !important;
    .c-angle-down {
      height: 7px;
      width: 7px;
      top: 14px;
      svg {
        fill: $danger;
      }
    }
    .c-angle-up {
      height: 7px;
      width: 7px;
      top: 14px;
      svg {
        fill: $danger;
      }
    }
    .c-list .c-token {
      background: none !important;
      .c-remove svg {
        fill: $danger !important;
      }
    }
  }

  .dropdown-list {
    .list-area {
      li {
        &.selected-item {
          background-color: $danger !important;
          label {
            color: #fff !important;
            text-decoration: none !important;
          }
        }
      }
    }
  }
}
.btn-success {
  .c-btn {
    color: $success !important;
    border: 1px solid $success !important;
    background: transparent !important;
    .c-angle-down {
      height: 7px;
      width: 7px;
      top: 14px;
      svg {
        fill: $success;
      }
    }
    .c-angle-up {
      height: 7px;
      width: 7px;
      top: 14px;
      svg {
        fill: $success;
      }
    }
    .c-list .c-token {
      background: none !important;
      .c-remove svg {
        fill: $success !important;
      }
    }
  }

  .dropdown-list {
    .list-area {
      li {
        &.selected-item {
          background-color: $success !important;
          label {
            color: #fff !important;
            text-decoration: none !important;
          }
        }
      }
    }
  }
}
.btn-warning {
  .c-btn {
    color: $warning !important;
    border: 1px solid $warning !important;
    background: transparent !important;
    .c-angle-down {
      height: 7px;
      width: 7px;
      top: 14px;
      svg {
        fill: $warning;
      }
    }
    .c-angle-up {
      height: 7px;
      width: 7px;
      top: 14px;
      svg {
        fill: $warning;
      }
    }
    .c-list .c-token {
      background: none !important;
      .c-remove svg {
        fill: $warning !important;
      }
    }
  }

  .dropdown-list {
    .list-area {
      li {
        &.selected-item {
          background-color: $warning !important;
          label {
            color: #fff !important;
            text-decoration: none !important;
          }
        }
      }
    }
  }
}

.cuppa-dropdown {
  .selected-list {
    .c-btn {
      padding: 10px !important;
      margin: 0 !important;
      margin-bottom: 5px !important;
      position: relative !important;
      width: 100% !important;
      z-index: 1 !important;
      text-align: right !important;
      white-space: nowrap !important;
    }
  }
  .dropdown-list {
    z-index: 1000 !important;

    .list-area {
      border: none !important;
      border-radius: 0.125rem !important;
      box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2) !important;
      z-index: 1000;
      float: left;
      min-width: 100%;
      padding: 0.5rem 0;
      font-size: 0.875rem;
      color: #525f7f;
      text-align: left;
      list-style: none;
      background-color: #fff;
      background-clip: padding-box;

      .list-filter input {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
      }

      li {
        font-size: 0.75rem !important;
        padding-top: 0.6rem !important;
        padding-bottom: 0.6rem !important;
        margin-top: 5px !important;
        transition: 0.15s, linear !important;
        &:hover {
          background-color: hsla(0, 0%, 87%, 0.3);
        }
        label {
          width: 100% !important;
          color: #9a9a9a !important;
        }
        input[type="checkbox"]:checked + label:before {
          background: transparent !important;
          animation: none !important;
          right: 0 !important;
        }
        input[type="checkbox"] + label:before {
          border: none !important;
        }
        input[type="checkbox"] + label:after {
          left: auto !important;
          width: 14px !important;
          height: 7px !important;
          border-width: 0 0 1px 1px !important;
          right: 0 !important;
          opacity: 0.5 !important;
        }
      }
      ul li:last-child {
        border-bottom-right-radius: 0.125rem;
        border-bottom-left-radius: 0.125rem;
      }
      ul li:first-child {
        border-top-left-radius: 0.125rem;
        border-top-right-radius: 0.125rem;
      }
    }

    .arrow-up {
      margin-left: 30px !important;
      border-bottom: 15px solid #fff;
    }
  }
}
angular2-multiselect {
  &,
  & * {
    outline: none;
  }
}
