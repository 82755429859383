// @import "node_modules/@costlydeveloper/ngx-awesome-popup/styles/theme";

.cursor-pointer:hover {
  cursor: pointer !important;
}

.cursor-move:hover {
  cursor: move;
}

.d-grid {
  display: grid !important;
}

:root {
  --default-font-family: "Poppins", sans-serif;
  --body-bg-color: #f5f5f5;
  --default-text-color: #333;
  --base-separation: 1rem;
  --half-separation: calc(var(--base-separation) / 2);
}

@import '@angular/cdk/overlay-prebuilt.css';
@import "~@ng-select/ng-select/themes/default.theme.css";

// Overlay backdrop styling
.overlay-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}

// Overlay panel styling
.overlay-panel {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.font-10 {
  font-size: 10px !important;
}

