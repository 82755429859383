.box-status {
  width: 45px;
  height: 45px;
  display: block;
  line-height: 53px;
  text-align: center;
  border-radius: 12%;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.box-status:before {
  -webkit-transform: rotate(-45deg) !important;
  transform: rotate(-45deg) !important;
  display: inline-block;
  font-size: 18px;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.box-success {
  color: #e2fbf8;
  background: #1ecab8;
}
.box-success:before {
  content: "\ea1b" !important;
}
.box-onhold {
  color: #f3effd;
  background-color: #7551e9;
}
.box-onhold:before {
  content: "\ea5e" !important;
}
.box-rejected {
  color: #ffecf4;
  background-color: #ff5da0;
}
.box-rejected:before {
  content: "\ea48" !important;
}
.box-replied {
  color: #fff;
  background-color:#f3c74d;
}
.box-replied:before {
  content: "\ea4c" !important;
}
